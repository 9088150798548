var render = function render(){var _vm=this,_c=_vm._self._c;return (true)?_c('div',[(_vm.showRemove && _vm.showRemove.user)?_c('molecules-custom-dialog',{attrs:{"show":_vm.showRemove,"title":"Apakah Anda Yakin?","caption":`Anda akan menghapus member (${
      (_vm.showRemove && _vm.showRemove.user && _vm.showRemove.user.nickname) || '-'
    }) dari client ${_vm.showRemove && _vm.showRemove.client && _vm.showRemove.client.name}`,"items":[
      {
        title: 'Batalkan',
        full: false,
        exec: () => (_vm.showRemove = null),
      },
      {
        title: 'Iya Hapus',
        override: 'red--text',
        full: false,
        exec: () => {
          _vm.removeUser()
        },
      },
    ]}}):_vm._e(),_c('v-form',{attrs:{"id":"filter"},on:{"submit":function($event){$event.preventDefault();return (() => {
        _vm.member.enabled = true
        _vm.loadData()
      }).apply(null, arguments)}}},[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"grow",attrs:{"cols":"12","md":"auto"}},[(!_vm.member.enabled)?_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return (() => {
              _vm.getMembers()
              _vm.member.enabled = true
            }).apply(null, arguments)}}},[_c('atoms-text-field',{staticClass:"rounded-lg accent",attrs:{"noLabel":""}},[_c('v-text-field',{staticClass:"rounded-lg ma-0 pa-0",style:({
                'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
              }),attrs:{"placeholder":"Ketikkan kata kunci pencarian...","appendIcon":"mdi-close","hide-details":"","flat":"","solo":"","dense":""},on:{"click:append":() => {
                  _vm.member = {
                    enabled: false,
                    value: '',
                    item: null,
                    term: '',
                  }
                  _vm.loadData()
                },"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return (() => {
                  _vm.getMembers()
                  // member.term = '' //! commented because it affected to pagination
                  _vm.member.enabled = true
                }).apply(null, arguments)}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-combobox',{staticClass:"rounded-lg ma-0 pa-0",attrs:{"placeholder":"Query","items":_vm.searchFilters.map((x) => x.title),"hide-details":"","multiple":"","dense":"","solo":"","flat":"","required":""},scopedSlots:_vm._u([{key:"selection",fn:function({ item, index }){return [_c('span',{staticClass:"pa-0",staticStyle:{"white-space":"nowrap"}},[_vm._v(_vm._s((index == 0 && item) || (index == 1 && '..') || '')+" ")])]}}],null,false,311806157),model:{value:(_vm.searchFilter),callback:function ($$v) {_vm.searchFilter=$$v},expression:"searchFilter"}})]},proxy:true}],null,false,2050599438),model:{value:(_vm.member.term),callback:function ($$v) {_vm.$set(_vm.member, "term", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"member.term"}})],1)],1):_c('atoms-text-field',{attrs:{"label":"Cari Member","no-label":true}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('atoms-button',{attrs:{"icon":""},on:{"click":() => {
                    _vm.member = {
                      enabled: false,
                      value: '',
                      item: null,
                      term: '',
                    }
                    _vm.loadData()
                  }}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1),_c('v-col',{attrs:{"cols":"11"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"items":_vm.$store.state.member.data.map((x) => x.user && x.user.nickname),"placeholder":` ${_vm.member.term ? `Pencarian Sekarang : ${_vm.member.term}.` : ''} Pilih Member (Opsional)`,"menu-props":{ offsetY: true },"hide-details":"","dense":"","solo":"","flat":""},model:{value:(_vm.member.value),callback:function ($$v) {_vm.$set(_vm.member, "value", $$v)},expression:"member.value"}})],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"10","md":"2","align":"end"}},[_c('atoms-button',{staticClass:"primary lighten-5 primary--text",staticStyle:{"width":"100%"},attrs:{"type":"submit"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v("Cari Member")],1)],1),_c('v-col',{staticClass:"shrink",attrs:{"cols":"2","md":"auto","align":"end"}},[_c('v-menu',{staticClass:"rounded-xl",attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2587636263)},[_c('v-list',{staticClass:"rounded-lg",attrs:{"nav":"","dense":"","elevation":"0"}},[_c('v-list-item',{staticClass:"rounded-lg px-5",attrs:{"link":""},on:{"click":function($event){_vm.showAdd = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Tambah Member ")])],1),_c('v-list-item',{staticClass:"rounded-lg px-5",attrs:{"link":""},on:{"click":function($event){_vm.showAddBulk = true}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-multiple")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Sesuaikan Area Lokasi Member ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('atoms-button',{staticClass:"primary",style:({
            width: _vm.$vuetify.breakpoint.smAndDown ? '100%' : 'auto',
          }),on:{"click":() => {
              _vm.sort = _vm.sort === _vm.constant[0] ? _vm.constant[1] : _vm.constant[0]
              _vm.getMembers()
            }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter-variant-"+_vm._s(_vm.sort === _vm.constant[0] ? 'minus' : 'plus'))]),_vm._v("Sort: "+_vm._s(_vm.sort))],1)],1)],1)],1),_c('div',[(_vm.mainLoading)?_c('molecules-wrapper',{staticClass:"px-0"},_vm._l((5),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"accent rounded-lg mb-2 pa-2",style:({
          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
        }),attrs:{"type":"list-item-avatar"}})}),1):(_vm.$store.state.member.data && _vm.$store.state.member.data.length > 0)?_c('molecules-wrapper',{staticClass:"px-0",attrs:{"fluid":""}},_vm._l((_vm.$store.state.member.data),function({ user, client, ...rest },i){return _c('div',{key:i,class:['accent', 'rounded-lg pa-4 mb-2'],style:({
          'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
        })},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"shrink"},[_c('v-avatar',{attrs:{"color":"grey lighten-4 "}},[(user && user.picture)?_c('atoms-image',{attrs:{"enableZoom":true,"src":user.picture}}):_c('v-icon',[_vm._v("mdi-account")])],1)],1),_c('v-col',{attrs:{"cols":"8","md":"4"}},[_c('atoms-text',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$toCapitalize((user && user.nickname) || 'Member')))]),_c('atoms-text',{staticClass:"primary--text"},[_vm._v("Client "+_vm._s((client && client.name) || '-'))])],1),_c('v-col',{attrs:{"align":"start"}},[_c('atoms-text',{staticClass:"font-weight-bold"},[_vm._v("Member sejak "+_vm._s(_vm.$moment(rest._createdDate).format('dddd, DD MMMM YYYY')))]),_c('atoms-text',{staticClass:"primary--text"},[_vm._v("Bergabung sebagai pengguna sejak "+_vm._s(_vm.$moment(user && user._createdDate).format('dddd, DD MMMM YYYY')))]),(rest.areas)?_c('atoms-text',{staticClass:"orange--text text--darken-2"},[_vm._v(" Area : "+_vm._s(rest.areas.map((area) => area.title).join(', ') || 'Tidak Ada')+" ")]):_vm._e()],1),_c('v-col',{staticClass:"shrink"},[_c('v-menu',{staticClass:"rounded-xl",attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{staticClass:"text-center rounded-lg",attrs:{"nav":"","dense":"","elevation":"0"}},[_c('v-list-item',{staticClass:"rounded-lg px-4 text-center",attrs:{"link":""},on:{"click":() => {
                      _vm.showRemove = {
                        user,
                        client,
                        ...rest,
                      }
                    }}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Hapus")])],1),_c('v-list-item',{staticClass:"rounded-lg px-4 text-center",attrs:{"link":""},on:{"click":function($event){_vm.showEdit = { user, client, ...rest }}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil")]),_c('v-list-item-title',{staticClass:"px-2"},[_vm._v("Edit ")])],1)],1)],1)],1)],1),_c('v-container',{staticClass:"px-0 pb-0",attrs:{"fluid":""}},_vm._l((rest.roles),function(x,i){return _c('v-chip',{key:i,staticClass:"rounded-lg mr-1",attrs:{"small":""}},[_vm._v(" "+_vm._s(x)+" ")])}),1)],1)}),0):_c('molecules-wrapper',{staticClass:"d-flex justify-center align-center my-8 py-8 outlined"},[_c('div',{staticClass:"text-center"},[_c('atoms-image',{attrs:{"width":250,"src":"/assets/missing.svg"}}),_c('atoms-title',{staticClass:"mt-6",attrs:{"h3":true}},[_vm._v("Tidak Dapat Menemukan Member")])],1)]),(_vm.$store.state.member.data && _vm.$store.state.member.data.length > 0)?_c('molecules-wrapper',{staticClass:"max-width"},[_c('v-pagination',{staticClass:"my-4",attrs:{"length":_vm.$store.state.member.pagesLength},model:{value:(_vm.membersPage),callback:function ($$v) {_vm.membersPage=$$v},expression:"membersPage"}})],1):_vm._e()],1),_c('v-slide-x-reverse-transition',[(_vm.showAddBulk)?_c('organism-members-register-bulk',{on:{"closed":() => {
          _vm.showAddBulk = false
        }}}):_vm._e()],1),_c('v-slide-x-reverse-transition',[(_vm.showAdd)?_c('organism-members-register',{on:{"closed":() => {
          _vm.showAdd = false
        }}}):_vm._e()],1),_c('v-slide-x-reverse-transition',[(_vm.showEdit)?_c('organism-members-edit',{attrs:{"data":_vm.showEdit},on:{"closed":() => {
          _vm.showEdit = null
        }}}):_vm._e()],1)],1):_c('div',[_c('atoms-text',[_vm._v("Akses Halaman di "),_c('a',{attrs:{"href":"https://hayosystems-v3.web.app/clients/member"}},[_vm._v("https://hayosystems-v3.web.app/clients/member")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }