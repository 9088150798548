<template>
  <div v-if="true">
    <molecules-custom-dialog
      v-if="showRemove && showRemove.user"
      :show="showRemove"
      title="Apakah Anda Yakin?"
      :caption="`Anda akan menghapus member (${
        (showRemove && showRemove.user && showRemove.user.nickname) || '-'
      }) dari client ${showRemove && showRemove.client && showRemove.client.name}`"
      :items="[
        {
          title: 'Batalkan',
          full: false,
          exec: () => (showRemove = null),
        },
        {
          title: 'Iya Hapus',
          override: 'red--text',
          full: false,
          exec: () => {
            removeUser()
          },
        },
      ]"
    />
    <v-form
      @submit.prevent="
        () => {
          member.enabled = true
          loadData()
        }
      "
      id="filter"
    >
      <v-row justify="space-between">
        <!-- <v-col cols="12" md="auto" class="grow">
          <atoms-text-field
            label="Cari Member"
            v-model.trim="term"
            :noLabel="true"
            :appendAction="
              () => {
                term = ''
                getMembers()
              }
            "
            appendIcon="mdi-close"
          />
        </v-col> -->
        <v-col cols="12" md="auto" class="grow">
          <v-form
            ref="form"
            v-if="!member.enabled"
            @submit.prevent="
              () => {
                getMembers()
                member.enabled = true
              }
            "
          >
            <atoms-text-field class="rounded-lg accent" noLabel>
              <v-text-field
                v-model.trim="member.term"
                :style="{
                  'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
                }"
                @click:append="
                  () => {
                    member = {
                      enabled: false,
                      value: '',
                      item: null,
                      term: '',
                    }
                    loadData()
                  }
                "
                @keydown.enter="
                  () => {
                    getMembers()
                    // member.term = '' //! commented because it affected to pagination
                    member.enabled = true
                  }
                "
                placeholder="Ketikkan kata kunci pencarian..."
                appendIcon="mdi-close"
                class="rounded-lg ma-0 pa-0"
                hide-details
                flat
                solo
                dense
              >
                <template v-slot:prepend>
                  <v-combobox
                    class="rounded-lg ma-0 pa-0"
                    placeholder="Query"
                    v-model="searchFilter"
                    :items="searchFilters.map((x) => x.title)"
                    hide-details
                    multiple
                    dense
                    solo
                    flat
                    required
                  >
                    <template v-slot:selection="{ item, index }">
                      <span style="white-space: nowrap" class="pa-0"
                        >{{ (index == 0 && item) || (index == 1 && '..') || '' }}
                      </span>
                    </template></v-combobox
                  >
                </template></v-text-field
              ></atoms-text-field
            >
          </v-form>
          <atoms-text-field v-else label="Cari Member" :no-label="true">
            <v-row dense>
              <v-col cols="1">
                <atoms-button
                  icon
                  @click="
                    () => {
                      member = {
                        enabled: false,
                        value: '',
                        item: null,
                        term: '',
                      }
                      loadData()
                    }
                  "
                  ><v-icon>mdi-delete</v-icon></atoms-button
                >
              </v-col>
              <v-col cols="11">
                <v-autocomplete
                  class="rounded-lg"
                  v-model="member.value"
                  :items="$store.state.member.data.map((x) => x.user && x.user.nickname)"
                  :placeholder="` ${member.term ? `Pencarian Sekarang : ${member.term}.` : ''} Pilih Member (Opsional)`"
                  :menu-props="{ offsetY: true }"
                  hide-details
                  dense
                  solo
                  flat
                />
              </v-col>
            </v-row>
          </atoms-text-field>
        </v-col>
        <v-col cols="10" md="2" align="end">
          <atoms-button type="submit" style="width: 100%" class="primary lighten-5 primary--text"
            ><v-icon left>mdi-magnify</v-icon>Cari Member</atoms-button
          >
        </v-col>
        <v-col cols="2" md="auto" class="shrink" align="end">
          <v-menu offset-x class="rounded-xl">
            <template v-slot:activator="{ on, attrs }">
              <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
            </template>
            <v-list nav dense elevation="0" class="rounded-lg">
              <v-list-item @click="showAdd = true" link class="rounded-lg px-5">
                <v-icon left>mdi-account</v-icon>
                <v-list-item-title class="px-2">Tambah Member </v-list-item-title>
              </v-list-item>
              <v-list-item @click="showAddBulk = true" link class="rounded-lg px-5">
                <v-icon left>mdi-account-multiple</v-icon>
                <v-list-item-title class="px-2">Sesuaikan Area Lokasi Member </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
        <v-col cols="12" md="12">
          <atoms-button
            class="primary"
            :style="{
              width: $vuetify.breakpoint.smAndDown ? '100%' : 'auto',
            }"
            @click="
              () => {
                sort = sort === constant[0] ? constant[1] : constant[0]
                getMembers()
              }
            "
            ><v-icon left>mdi-filter-variant-{{ sort === constant[0] ? 'minus' : 'plus' }}</v-icon
            >Sort: {{ sort }}</atoms-button
          >
        </v-col>
      </v-row>
    </v-form>
    <!-- content member -->
    <div>
      <molecules-wrapper v-if="mainLoading" class="px-0">
        <v-skeleton-loader
          v-for="i in 5"
          :key="i"
          type="list-item-avatar"
          class="accent rounded-lg mb-2 pa-2"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        />
      </molecules-wrapper>
      <molecules-wrapper v-else-if="$store.state.member.data && $store.state.member.data.length > 0" fluid class="px-0">
        <div
          v-for="({ user, client, ...rest }, i) in $store.state.member.data"
          :key="i"
          :class="['accent', 'rounded-lg pa-4 mb-2']"
          :style="{
            'box-shadow': 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
          }"
        >
          <v-row align="center">
            <v-col class="shrink">
              <v-avatar color="grey lighten-4 ">
                <atoms-image v-if="user && user.picture" :enableZoom="true" :src="user.picture" />
                <v-icon v-else>mdi-account</v-icon></v-avatar
              >
            </v-col>
            <v-col cols="8" md="4">
              <atoms-text class="font-weight-bold">{{ $toCapitalize((user && user.nickname) || 'Member') }}</atoms-text>
              <atoms-text class="primary--text">Client {{ (client && client.name) || '-' }}</atoms-text>
            </v-col>
            <v-col align="start">
              <atoms-text class="font-weight-bold"
                >Member sejak {{ $moment(rest._createdDate).format('dddd, DD MMMM YYYY') }}</atoms-text
              >
              <atoms-text class="primary--text"
                >Bergabung sebagai pengguna sejak
                {{ $moment(user && user._createdDate).format('dddd, DD MMMM YYYY') }}</atoms-text
              >
              <atoms-text v-if="rest.areas" class="orange--text text--darken-2">
                Area :
                {{ rest.areas.map((area) => area.title).join(', ') || 'Tidak Ada' }}
              </atoms-text>
            </v-col>
            <v-col class="shrink">
              <v-menu offset-x class="rounded-xl">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-list nav dense elevation="0" class="text-center rounded-lg">
                  <v-list-item
                    @click="
                      () => {
                        showRemove = {
                          user,
                          client,
                          ...rest,
                        }
                      }
                    "
                    link
                    class="rounded-lg px-4 text-center"
                  >
                    <v-icon left>mdi-delete</v-icon>
                    <v-list-item-title class="px-2">Hapus</v-list-item-title>
                  </v-list-item>
                  <v-list-item link @click="showEdit = { user, client, ...rest }" class="rounded-lg px-4 text-center">
                    <v-icon left>mdi-pencil</v-icon>
                    <v-list-item-title class="px-2">Edit </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
          <v-container fluid class="px-0 pb-0">
            <v-chip small class="rounded-lg mr-1" v-for="(x, i) in rest.roles" :key="i">
              {{ x }}
            </v-chip>
          </v-container>
        </div>
      </molecules-wrapper>
      <molecules-wrapper v-else class="d-flex justify-center align-center my-8 py-8 outlined">
        <div class="text-center">
          <atoms-image :width="250" src="/assets/missing.svg" />
          <atoms-title :h3="true" class="mt-6">Tidak Dapat Menemukan Member</atoms-title>
        </div>
      </molecules-wrapper>
      <molecules-wrapper v-if="$store.state.member.data && $store.state.member.data.length > 0" class="max-width">
        <v-pagination class="my-4" v-model="membersPage" :length="$store.state.member.pagesLength"></v-pagination>
      </molecules-wrapper>
    </div>
    <v-slide-x-reverse-transition>
      <organism-members-register-bulk
        v-if="showAddBulk"
        @closed="
          () => {
            showAddBulk = false
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-members-register
        v-if="showAdd"
        @closed="
          () => {
            showAdd = false
          }
        "
      />
    </v-slide-x-reverse-transition>
    <v-slide-x-reverse-transition>
      <organism-members-edit
        v-if="showEdit"
        :data="showEdit"
        @closed="
          () => {
            showEdit = null
          }
        "
      />
    </v-slide-x-reverse-transition>
  </div>
  <div v-else>
    <atoms-text
      >Akses Halaman di
      <a href="https://hayosystems-v3.web.app/clients/member"
        >https://hayosystems-v3.web.app/clients/member</a
      ></atoms-text
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      mainLoading: true,
      selectedData: null,
      show: false,
      showAdd: false,
      showAddBulk: false,
      showEdit: null,
      showRemove: null,
      editableId: '',
      editableName: '',
      sort: 'Desc',
      constant: ['Desc', 'Asc'],
      membersPage: this.$store.state.member.page,
      page: 1,
      items: null,
      limit: 5,
      term: '',
      workerLoading: true,
      reportLoading: true,
      member: {
        enabled: false,
        value: '',
        item: null,
        items: null,
        term: '',
      },
      searchFilter: null,
      searchFilters: [
        {
          title: 'Nama Client',
          value: (term) => ({
            'client.name': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Nama Member',
          value: (term) => ({
            'user.nickname': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Email',
          value: (term) => ({
            'user.email': {
              $regex: term,
              $options: 'i',
            },
          }),
        },
        {
          title: 'Roles',
          value: (term) => ({
            roles: {
              $regex: term,
              $options: 'i',
            },
          }),
        },
      ],
    }
  },
  computed: {
    getShow: {
      get() {
        return this.show
      },
      set(value) {
        this.show = value
      },
    },
  },
  watch: {
    'member.value'() {
      if (this.member.value) {
        this.member.item = this.$store.state.member?.data?.find((x) => x.user?.nickname === this.member.value)
        this.loadData()
      }
    },
    page: {
      async handler() {
        this.getWorker()
      },
      deep: true,
    },
    membersPage: {
      async handler() {
        this.getMembers()
      },
      deep: true,
    },
  },

  async mounted() {
    // window.open('https://hayosystems-v3.web.app/clients/member', '_blank')
    // this.loadData()
  },
  methods: {
    async loadData() {
      this.getMembers()
    },
    async getSummary() {
      this.reportLoading = true
      await this.$store.dispatch('attendance/getSummary')
      await this.$store.dispatch('member/getSummary')
      this.reportLoading = false
    },
    async removeMember(id, name) {
      try {
        this.$store.commit('SET_LOADING', true)
        await this.$store
          .dispatch('member/remove', id)
          .then(async (x) => {
            this.$showDialog({
              title: 'Berhasil',
              body: `Berhasil menghapus member ${this.$toCapitalize(name || '')}`,
            })
            await this.loadData()
          })
          .catch(() =>
            this.$showDialog({
              title: 'Gagal',
              body: `Gagal menghapus member ${this.$toCapitalize(name || '')}`,
            }),
          )
      } finally {
        this.$store.commit('SET_LOADING', false)
      }
    },
    async getMembers() {
      this.mainLoading = true

      await this.$store.dispatch('member/getAll', {
        custom: {
          pipeline: [
            {
              $sort: {
                _createdDate: this.sort === this.constant[0] ? -1 : 1,
              },
            },
            ...this.$store.state.member.defaultPipeline,
            {
              $match: {
                _id: this.member.item?._id,
                $or: [
                  ...(this.searchFilter?.length > 0
                    ? this.searchFilter?.map((x) =>
                        this.searchFilters?.find((y) => y.title === x)?.value?.(this.member.term),
                      )
                    : this.searchFilters.map((x) => x?.value?.(this.member.term))),
                ],
              },
            },
          ],
        },
        page: this.membersPage,
        limit: this.limit,
      })
      this.$vuetify.goTo(0)
      this.mainLoading = false
    },
    async connectAsMember(email) {
      this.$store.commit('SET_LOADING', true)
      try {
        const check = await this.$store.dispatch('user/getUsers', {
          email: { $regex: email || '', $options: 'i' },
        })
        if (check?.result?.[0]) {
          await this.$store
            .dispatch('member/registerMember', check?.result?.[0]._id)
            .then((x) => {
              if (x?.success) {
                this.$store.commit('dialog/show', {
                  title: 'Berhasil',
                  body: `Member baru berhasil ditambahkan!`,
                })
              }
            })
            .catch((error) => {
              this.$store.commit('dialog/show', {
                title: 'Gagal',
                body: `Pekerja tersebut sudah menjadi member!`,
              })
            })
        } else {
          this.$store.commit('dialog/show', {
            title: 'Gagal',
            body: `Pekerja tersebut bukan lagi pengguna hayokerja!`,
          })
        }
        await this.getMember()
      } catch (error) {
        Promise.reject(error)
      } finally {
        this.$store.commit('SET_LOADING', false)
      }
    },
    async removeUser() {
      this.$store.commit('SET', { loading: true })
      try {
        if (this.showRemove?._id) {
          await this.$store.dispatch('member/remove', this.showRemove?._id).then(() => {
            this.$showDialog({
              title: 'Berhasil',
              body: `${this.showRemove?.user?.nickname || 'Member'} berhasil dihapus`,
            })
            this.showRemove = null
            this.loadData()
          })
        } else {
          this.$showDialog({
            title: 'Kesalahan',
            body: 'Email yang anda masukkan tidak sesuai dengan email user yang akan dihapus!',
          })
        }
      } catch (error) {
        this.$showDialog({
          title: 'Kesalahan',
          body: `Terdapat kesalahan, ${error.message || error}`,
        })
        this.$store.commit('SET', { loading: false })
        Promise.reject(error)
      }
    },
  },
}
</script>
